import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RruleService } from './rrule.service';
import { formatDate, getDateParts } from './util/common';
import { computeRRule } from './util/computeRRule/fromString/computeRRule';
import { RRule } from 'rrule';

@Component({
  selector: 'hcp-rrule',
  templateUrl: './rrule.component.html',
  styles: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RruleComponent),
    multi: true
  }],
  standalone: false
})
export class RruleComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() hideStart: boolean = false;
  @Input() hideEnd: boolean = false;
  @Input() startAt: Date;
  @Input() endAt: Date;
  @Input() frequency: string;
  @Input() tz: string;
  public form: FormGroup;

  @Input() rrule: string = ''; // Bind initial value from parent
  @Output() updates$ = new EventEmitter<RRule>(); // Two-way binding for RRULE string

  constructor(private formBuilder: FormBuilder,
    private service: RruleService) { }

  ngOnInit() {
    const params: any = {
      start: {},
      repeat: {},
      end: {
        mode: 'Never'
      }
    };

    if (this.endAt) {
      params.end = {
        mode: 'On date',
        onDate: {
          date: getDateParts(this.endAt)
        }
      }
    }

    if (this.startAt) {
      params.start = {
        onDate: {
          date: getDateParts(this.startAt)
        }
      }
    }

    this.form = this.formBuilder.group(params);

    this.form.valueChanges.subscribe(() => setTimeout(() => {
      this.onFormChange();
    }, 100));
  }

  writeValue = (input: any): void => {
    const config: any = {};
    const configureFrequency = () => (config.repeat ? config.repeat[0] : 'Yearly');
    const configureYearly = () => (config.yearly || 'on');
    const configureMonthly = () => (config.monthly || 'on');
    const configureEnd = () => (config.end ? config.end[0] : 'Never');
    const configureHideStart = () => (typeof config.hideStart === 'undefined' ? true : config.hideStart);
    // const uniqueRruleId = isEmpty(id) ? uniqueId('rrule-') : id;
    const init_data = {
      start: {
        onDate: {
          date: formatDate(new Date()),
          options: {},
        },
      },
      repeat: {
        frequency: configureFrequency(),
        yearly: {
          mode: configureYearly(),
          on: {
            month: 'Jan',
            day: 1,
          },
          onThe: {
            month: 'Jan',
            day: 'Monday',
            which: 'First',
          },
          options: {
            // modes: config.yearly,
          },
        },
        monthly: {
          mode: configureMonthly(),
          interval: 1,
          on: {
            day: 1,
          },
          onThe: {
            day: 'Monday',
            which: 'First',
          },
          options: {
            // modes: config.monthly,
          },
        },
        weekly: {
          interval: 1,
          days: {
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false,
          },
          options: {
            // weekStartsOnSunday: config.weekStartsOnSunday,
          },
        },
        daily: {
          interval: 1,
        },
        hourly: {
          interval: 1,
        },
        options: {
          // frequency: config.repeat,
        },
      },
      end: {
        mode: configureEnd(),
        after: 1,
        onDate: {
          date: formatDate(new Date()),
          options: {
            // weekStartsOnSunday: config.weekStartsOnSunday,
            // calendarComponent,
          },
        },
        options: {
          modes: config.end,
        },
      },
      options: {
        hideStart: configureHideStart(),
        hideEnd: config.hideEnd,
        hideError: config.hideError,
        weekStartsOnSunday: config.weekStartsOnSunday,
      },
      error: null,
    };


    const data = computeRRule(init_data, input);
    this.form.patchValue(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnChange(fn: any): void {
    // this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: any): void {
  }

  onFormChange = () => {
    let rRule;
    try {
      const params = this.form.value;
      if (this.hideStart && !this.startAt) {
        params.start = null;
      }
      if (this.hideEnd && !this.endAt) {
        params.end = null;
      }
      rRule = this.service.computeRRule({ ...params, options: {tz: this.tz} });
    } catch (err) {
      console.error(err);
    }

    this.updates$.emit(rRule);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.onFormChange();
    }, 10)
  }
}
