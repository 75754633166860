import { RRule } from 'rrule';
import computeEnd from './computeEnd';
import computeOptions from './computeOptions';
import computeRepeat from './computeRepeat';

import computeStart from './computeStart';

export const computeRRule = ({
  start,
  repeat,
  end,
  options,
}) => {
  const rruleObject = {
    ...computeStart(start),
    ...computeRepeat(repeat),
    ...computeEnd(end),
    ...computeOptions(options),
  };
  return new RRule(rruleObject);
};

