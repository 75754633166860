<form [formGroup]="form">
  <div class="row col-lg-12 form-inline">
    <label class="mr-2">Repeat every</label>
    <input aria-label="Repeat interval" class="form-control w-25" value="1" formControlName="interval">

    <select formControlName="frequency" (change)="onOptionChange()" class="form-control mt-md-0 mt-sm-2">
      <option value="Yearly" *ngIf="!frequency || frequency.indexOf('Yearly') !== -1">Year</option>
      <option value="Monthly" *ngIf="!frequency || frequency.indexOf('Monthly') !== -1">Month</option>
      <option value="Weekly" *ngIf="!frequency || frequency.indexOf('Weekly') !== -1">Week</option>
      <option value="Daily" *ngIf="!frequency || frequency.indexOf('Daily') !== -1">Day</option>
      <option value="Hourly" *ngIf="!frequency || frequency.indexOf('Hourly') !== -1">Hour</option>
    </select>

  </div>
  <div class="row col mt-1 mb-1">
    <ngx-yearly *ngIf="form.value.frequency === 'Yearly'" formControlName="yearly"></ngx-yearly>
    <ngx-monthly *ngIf="form.value.frequency === 'Monthly'" formControlName="monthly"></ngx-monthly>
    <ngx-weekly *ngIf="form.value.frequency === 'Weekly'" formControlName="weekly"></ngx-weekly>
  </div>
</form>
