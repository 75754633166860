import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'hcp-end',
  templateUrl: './end.component.html',
  standalone: false
})
export class EndComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      mode: ['Never'], // Default value for dropdown
      after: [null],   // Number of occurrences
      endAt: [null],   // Date for 'On date'
    });
  }
}
