<form [formGroup]="form" class="form-inline">
  <div class="row">
    <div class="form-group col-lg-12">
      <label class="mr-2">Ends</label>
      <mat-form-field appearance="fill">
        <mat-label>End Mode</mat-label>
        <mat-select formControlName="mode">
          <mat-option value="Never">Never</mat-option>
          <mat-option value="After">After</mat-option>
          <mat-option value="On date">On date</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="form.value.mode === 'After'">
        <div class="form-group row d-flex align-items-center">
          <mat-form-field appearance="fill" class="ml-2">
            <mat-label>Occurrences</mat-label>
            <input matInput formControlName="after" type="number" />
          </mat-form-field>
          <label class="ml-1">occurrences</label>
        </div>
      </div>

      <div *ngIf="form.value.mode === 'On date'">
        <mat-form-field appearance="fill" class="ml-2">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="endAt" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
