<mat-form-field appearance="fill">
  <mat-label>Start Date</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [value]="value"
    (dateChange)="onDateChange($event)"
    placeholder="Choose a date"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
