<form [formGroup]="form">
    <div class="row mt-2">
      <div class="form-inline">
        <div class="form-group col-lg-12">
          <input type="radio" aria-label="Repeat yearly on" value="on" formControlName="mode">
          <label class="ml-2">on</label>
          <div  formGroupName="on">
            <select formControlName="month" aria-label="Repeat yearly on month" class="form-control"
                    [attr.disabled]="form.value.mode !== 'on'? '' : null">
              <option value="Jan">Jan</option>
              <option value="Feb">Feb</option>
              <option value="Mar">Mar</option>
              <option value="Apr">Apr</option>
              <option value="May">May</option>
              <option value="Jun">Jun</option>
              <option value="Jul">Jul</option>
              <option value="Aug">Aug</option>
              <option value="Sep">Sep</option>
              <option value="Oct">Oct</option>
              <option value="Nov">Nov</option>
              <option value="Dec">Dec</option>
            </select>
            <select formControlName="day" aria-label="Repeat yearly on a day" class="form-control"
                    [attr.disabled]="form.value.mode !== 'on'? '' : null">
              <option *ngFor="let val of range(1, 32)" [value]="val.toString()">{{val}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  <div class="row mt-2">
    <div class="form-inline">
      <div class="col-md-12 form-group">
        <input type="radio" value="on the" formControlName="mode">
        <label class="ml-2">on the</label>
        <div formGroupName="onThe">
        <select formControlName="which" aria-label="Repeat yearly on the which" class="form-control"
                [attr.disabled]="form.value.mode === 'on'? '' : null">
          <option value="First">First</option>
          <option value="Second">Second</option>
          <option value="Third">Third</option>
          <option value="Fourth">Fourth</option>
          <option value="Last">Last</option>
        </select>

        <select  formControlName="day" aria-label="Repeat yearly on the day" class="form-control"
                [attr.disabled]="form.value.mode === 'on'? '' : null">
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
          <option value="Day">Day</option>
          <option value="Weekday">Weekday</option>
          <option value="Weekend day">Weekend day</option>
        </select>
        </div>
        <label class="ml-1 mr-1">of</label>
        <div formGroupName="onThe">
        <select  formControlName="month" aria-label="Repeat yearly on the month" class="form-control"
                [attr.disabled]="form.value.mode === 'on'? '' : null">
          <option value="Jan">Jan</option>
          <option value="Feb">Feb</option>
          <option value="Mar">Mar</option>
          <option value="Apr">Apr</option>
          <option value="May">May</option>
          <option value="Jun">Jun</option>
          <option value="Jul">Jul</option>
          <option value="Aug">Aug</option>
          <option value="Sep">Sep</option>
          <option value="Oct">Oct</option>
          <option value="Nov">Nov</option>
          <option value="Dec">Dec</option>
        </select>
        </div>
      </div>
    </div>
  </div>
</form>
