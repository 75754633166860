import { RRule } from 'rrule';

const computeWeekly = ({ interval, days }: { interval: number; days: Record<number, boolean> }) => ({
  freq: RRule.WEEKLY,
  interval,
  byweekday: Object.values(days).reduce<number[]>(
    (activeDays, isDayActive, dayIndex) =>
      isDayActive ? [...activeDays, dayIndex] : activeDays,
    [],
  ),
});

export default computeWeekly;
