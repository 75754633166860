import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'hcp-start',
  templateUrl: './start.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StartComponent),
      multi: true,
    },
  ],
  standalone: false
})
export class StartComponent implements ControlValueAccessor {
  value: Date | null = null; // The value bound to the datepicker

  constructor(private dateAdapter: DateAdapter<any>) {
    console.log('DateAdapter available:', this.dateAdapter);
  }

  private onChange: (value: Date | null) => void = () => {};
  private onTouched: () => void = () => {};

  // Called by Angular to write a value to the component
  writeValue(value: Date | null): void {
    this.value = value;
  }

  // Register the callback to notify Angular when the value changes
  registerOnChange(fn: (value: Date | null) => void): void {
    this.onChange = fn;
  }

  // Register the callback to notify Angular when the component is touched
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Triggered when the user selects a date
  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    const selectedDate = event.value; // MatDatepickerInputEvent provides 'value' as a Date object
    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      this.value = selectedDate; // Update the internal value
      this.onChange(selectedDate); // Notify Angular form system
    } else {
      console.error('Invalid date selected:', event.value);
    }
    this.onTouched(); // Mark the field as touched
  }
}
