import { Injectable } from '@angular/core';
import { computeRRule } from './util/computeRRule/toString/computeRRule';
import { RRule } from 'rrule';

@Injectable({
  providedIn: 'root'
})
export class RruleService {

  constructor() {}

  computeRRule(params: any): RRule {
    let rRule = computeRRule(params);
    console.log('Generated RRULE:', rRule, 'from:', params);
    return rRule;
  }
}
