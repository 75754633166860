export const getDateParts = (date: Date): DateParts => {
  return isValidDate(date) ? {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  } : {} as DateParts;
}

export const isValidDate = (d: any): boolean => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const formatDate = (date: Date | string) => {
  if (date instanceof Date) {
    return date.toISOString().split('T')[0];
  }

  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export class DateParts {
  year: number;
  month: number;
  day: number;
}
