import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EndComponent } from './components/end/end.component';
import { MonthlyComponent } from './components/repeat/monthly/monthly.component';
import { RepeatComponent } from './components/repeat/repeat.component';
import { WeeklyComponent } from './components/repeat/weekly/weekly.component';
import { YearlyComponent } from './components/repeat/yearly/yearly.component';
import { StartComponent } from './components/start/start.component';
import { RruleComponent } from './rrule.component';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';

@NgModule({
  declarations: [
    EndComponent,
    MonthlyComponent,
    RepeatComponent,
    RruleComponent,
    StartComponent,
    WeeklyComponent,
    YearlyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDateFnsModule, // Import the Material DateFns module
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  providers: [
  ],
  exports: [RruleComponent]
})
export class RruleModule { }
