import { getDateParts, isValidDate } from '../../common';

const isObjectEmpty = (obj: any): boolean => {
  // Check if obj is null or undefined
  if (obj === null || obj === undefined) {
    return true
  }

  // Check if obj is a Date instance
  if (obj instanceof Date) {
    return false // Dates are not empty
  }

  // Check if obj is an object and has enumerable properties
  if (typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).length === 0
  }

  // All other cases (primitives, arrays, etc.)
  return false
}

const computeStart = (date) => {
  let start: any = {}

  if (isObjectEmpty(date)) {
    return start
  }

  if (date) {
    if (!date.onDate || !isValidDate(date.onDate.date)) {
      start = new Date(new Date().setMilliseconds(0)); // Creates a new Date object
    } else {
      start = date.onDate.date
    }

    const dateParts = getDateParts(start);
    return {
      dtstart: new Date(Date.UTC(dateParts.year, dateParts.month - 1, dateParts.day, 0, 0, 0, 0))
    };
  }

  return start
}

export default computeStart
